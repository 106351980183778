<template>
  <div>
    <!-- <h2>รายชื่อวิชาสอบออนไลน์ภายในคณะ</h2> -->
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">รายชื่อวิชาสอบออนไลน์ภายในคณะ</h4>
            <!-- <div class="small text-muted">คณะวิศวกรรมศาสตร์</div> -->
          </CCol>
        </CRow>
        <CDataTable
          :items="itemsReport"
          :fields="fieldsReport"
          :table-filter="{ placeholder: 'คำที่ต้องการค้นหา...',label: 'Search : '}"
          items-per-page-select
          :items-per-page="10"
          hover
          sorter
          pagination
          border
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="showExamResultModalFunc(item, index)"
              >
                Show
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
          <!-- Modal Send Exam result Component -->
  <CModal
    title="ข้อมูลสรุปการสอบ"
    :show.sync="showExamResultModal"
  >
  <CRow>
      <CCol class="text-center">
          <div v-show="showloading" class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </CCol>
  </CRow>
  <div v-show="!showloading">
    <center><h2>รายงานการคุมสอบ</h2></center>
      <table class="table">
        <tbody>
        <tr >
          <td>
            <CRow>
              <CCol class="text-right" col=6>
                <b>มาสอบ</b>
              </CCol>
              <CCol col=3>
                {{reportStatusList.ok.length}}
              </CCol>
              <CCol col=3>
                <CButton size="sm" @click="showCollapseDetail.ok = !showCollapseDetail.ok" color="outline-primary">{{showCollapseDetail.ok ? 'Hide' : 'Show'}} detail</CButton><br>
              </CCol>
            </CRow>
            <CRow>
              <CCol col=12>
                <CCollapse :show="showCollapseDetail.ok" :duration="400" class="pt-4">
                  <CCard body-wrapper>
                    <!-- <CCardText class="text-center">ฐาปกรณ์ เผยพร รหัสนักศึกษา 590610613<br>ณัฐพงษ์ พงษ์คำ รหัสนักศึกษา 590610614</CCardText> -->
                    <CDataTable
                      hover
                      stripe
                      :table-filter="{ placeholder: 'ค้นหา...',label: ' '}"
                      sorter
                      items-per-page-select
                      :items="reportStatusList.ok"
                      :fields="fieldsConcludeReport"
                      :items-per-page="5"
                      :active-page="activePage"
                      :pagination="{ doubleArrows: false, align: 'center'}"
                      @page-change="pageChange"
                    >
                    <template #check_in_unix="data">
                      <td>
                        <div v-if="data.item.check_in_unix !== null">
                          {{new Date(data.item.check_in_unix*1000).toLocaleString('th-TH')}}
                        </div>
                      </td>
                    </template>
                    </CDataTable>
                  </CCard>
                </CCollapse>
              </CCol>
            </CRow>
          </td>
        </tr>
        <tr >
          <td>
            <CRow>
              <CCol class="text-right" col=6>
                <b>ขาดสอบ</b>
              </CCol>
              <CCol col=3>
                {{reportStatusList.absent.length}}
              </CCol>
              <CCol col=3>
                <CButton size="sm" @click="showCollapseDetail.absent = !showCollapseDetail.absent" color="outline-primary">{{showCollapseDetail.absent ? 'Hide' : 'Show'}} detail</CButton><br>
              </CCol>
            </CRow>
            <CRow>
              <CCol col=12>
                <CCollapse :show="showCollapseDetail.absent" :duration="400" class="pt-4">
                  <CCard body-wrapper>
                    <!-- <CCardText class="text-center">ฐาปกรณ์ เผยพร รหัสนักศึกษา 590610613<br>ณัฐพงษ์ พงษ์คำ รหัสนักศึกษา 590610614</CCardText> -->
                    <CDataTable
                      hover
                      stripe
                      :table-filter="{ placeholder: 'ค้นหา...',label: ' '}"
                      sorter
                      items-per-page-select
                      :items="reportStatusList.absent"
                      :fields="fieldsConcludeReport"
                      :items-per-page="5"
                      :active-page="activePage"
                      :pagination="{ doubleArrows: false, align: 'center'}"
                      
                    >
                    <template #check_in_unix="data">
                      <td>
                        <div v-if="data.item.check_in_unix !== null">
                          {{new Date(data.item.check_in_unix*1000).toLocaleString('th-TH')}}
                        </div>
                        <div v-else>
                          ยังไม่ได้เช็คอิน
                        </div>
                      </td>
                    </template>
                    </CDataTable>
                  </CCard>
                </CCollapse>
              </CCol>
            </CRow>
          </td>
        </tr>
        <tr >
          <td>
            <CRow>
              <CCol class="text-right" col=6>
                <b>มาสาย</b>
              </CCol>
              <CCol col=3>
                {{reportStatusList.late.length}}
              </CCol>
              <CCol col=3>
                <CButton size="sm" @click="showCollapseDetail.late = !showCollapseDetail.late" color="outline-primary">{{showCollapseDetail.late ? 'Hide' : 'Show'}} detail</CButton><br>
              </CCol>
            </CRow>
            <CRow>
              <CCol col=12>
                <CCollapse :show="showCollapseDetail.late" :duration="400" class="pt-4">
                  <CCard body-wrapper >
                    <CDataTable
                      hover
                      stripe
                      :table-filter="{ placeholder: 'ค้นหา...',label: ' '}"
                      sorter
                      items-per-page-select
                      :items="reportStatusList.late"
                      :fields="fieldsConcludeReport"
                      :items-per-page="5"
                      :active-page="activePage"
                      :pagination="{ doubleArrows: false, align: 'center'}"
                      @page-change="pageChange"
                    >
                    <template #check_in_unix="data">
                      <td>
                        <div v-if="data.item.check_in_unix !== null">
                          {{new Date(data.item.check_in_unix*1000).toLocaleString('th-TH')}}
                        </div>
                        <div v-else>
                          ยังไม่ได้เช็คอิน
                        </div>
                      </td>
                    </template>
                    </CDataTable>
                  </CCard>
                </CCollapse>
              </CCol>
            </CRow>
          </td>
        </tr>
        <tr >
          <td>
            <CRow>
              <CCol class="text-right" col=6>
                <b>ไม่มีชื่อ</b>
              </CCol>
              <CCol col=3>
                {{reportStatusList.NotFound.length}}
              </CCol>
              <CCol col=3>
                <CButton size="sm" @click="showCollapseDetail.NotFound = !showCollapseDetail.NotFound" color="outline-primary">{{showCollapseDetail.NotFound ? 'Hide' : 'Show'}} detail</CButton><br>
              </CCol>
            </CRow>
            <CRow>
              <CCol col=12>
                <CCollapse :show="showCollapseDetail.NotFound" :duration="400" class="pt-4">
                  <CCard body-wrapper>
                    <CDataTable
                      hover
                      stripe
                      :table-filter="{ placeholder: 'ค้นหา...',label: ' '}"
                      sorter
                      items-per-page-select
                      :items="reportStatusList.NotFound"
                      :fields="fieldsConcludeReport"
                      :items-per-page="5"
                      :active-page="activePage"
                      :pagination="{ doubleArrows: false, align: 'center'}"
                      @page-change="pageChange"
                    >
                    
                    <template #check_in_unix="data">
                      <td>
                        <div v-if="data.item.check_in_unix !== null">
                          {{new Date(data.item.check_in_unix*1000).toLocaleString('th-TH')}}
                        </div>
                        <div v-else>
                          ยังไม่ได้เช็คอิน
                        </div>
                      </td>
                    </template>
                    </CDataTable>
                  </CCard>
                </CCollapse>
              </CCol>
            </CRow>
          </td>
        </tr>
        <tr >
          <td>
            <CRow>
              <CCol col=12>
                <CInput
                  type="text"
                  prepend="หมายเหตุ"
                  :value="detailShow"
                  required
                  plaintext
                />
              </CCol>
              
            </CRow>
          </td>
        </tr>
        </tbody>
        <!-- <center>
          <CButton size="lg" @click="sendExamResult()" color="success">ส่งรายงาน</CButton><br>
          <small><CIcon  name="cil-lightbulb"/>คุณสามารถส่งรายงานคุมสอบได้ไม่จำกัดโดยระบบจะยึดข้อมูลล่าสุดเป็นหลัก</small>
        </center> -->
      </table>
    </div>
  
  <template #footer>
    <!-- <CButton @click="afterAcceptPolicy()" color="success">ยืนยัน</CButton> -->
    <CButton @click="showExamResultModal = false" color="secondary">ยกเลิก</CButton>
  </template>
  </CModal>
  </div>
</template>

<script>

const fieldsReport = [
  { key: 'index', label: 'ลำดับ' },
  { key: 'eventName', label: 'ชื่อEvent', _style:'min-width:200px' },
  // { key: 'courseName', label: 'ชื่อวิชา', _style:'min-width:200px' },
  { key: 'courseId', label: 'รหัสวิชา' },
  // { key: 'sections', label: 'เซค' },
  { key: 'dateTime', label: 'วันที่สอบ'},
  { key: 'owner', label: 'ผู้คุมสอบ' },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
]

import Api from "@/services/apiServices"

export default {
  name: 'EventListFacultyAdmin',
  components: {
  },
  data () {
    return {
      details: [],
      collapseDuration: 0,
      fieldsReport,
      itemsReport: [],
      showExamResultModal:false,
      showCollapseDetail:{
        ok:false,
        absent:false,
        late:false,
        NotFound:false
      },
      reportStatusList:{
        ok:[],
        absent:[],
        late:[],
        NotFound:[]
      },
      reportDetails:"",
      fieldsConcludeReport: [
        { key: 'name', label: 'ชื่อ', _classes: 'font-weight-bold' },
        { key: 'studentid', label: 'รหัสนักศึกษา' },
        { key: 'check_in_unix', label: 'เวลาเช็คอิน' },
        /*{ key: 'state_exam', label: 'Camera' },
        { key: 'state_exam', label: 'Microphone' },*/
      ],
      activePage: 1,
      pageChange (val) {
        this.$router.push({ query: { id: this.eventID,page: val }})
      },
      detailShow:"",
      showloading:false
    }
  },
  created() {
    if(localStorage.getItem('Admin') === 'user'){
      this.$router.push({path: '/pages/404'})
    }else{
      this.getFacultyReport()
    }
  },
  methods: {
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    toggleDetails (item) {
      this.$set(this.items[item.id], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    },
    async showModalReport (item, index) {
      console.log(item)
      console.log(index)
      this.showExamResultModal = true
    },
    async getFacultyReport () {
      let AdminData = await this.getAdminFaculty()
      console.log("Faculty Code:",AdminData.fac_no)
      let facultyReport = await Api.getFacultyReport(AdminData.fac_no)
      console.log("facultyReport:",facultyReport)
      if(facultyReport.status){
        await this.turnDataToShowInTable(facultyReport.data)
      }
      
    },
    async getAdminFaculty () {
      let userRole = await Api.getUserRole(localStorage.getItem('email'))
      if(userRole){
        console.log("checkUserRole:",userRole)
      }
      return userRole.data
    },
    async turnDataToShowInTable (data) {
      let report = {}
      let sectionLists = []
      for(let i=0;i<data.length;i++){
        report = { 
          _id:"",
          eventName: "",
          dateTime: "",
          courseId: "",
          sections: [],
          owner: "",
          detail: ""
        }
        sectionLists = []
        if(data[i].courseid){
          report._id = data[i]._id
          report.eventName = data[i].titlename
          report.detail = data[i].detail
          // report.courseName =  data[i].courses[0].courseName
          report.dateTime =  new Date(data[i].timestamp*1000).toLocaleString('th-TH')
          report.courseId =  data[i].courseid
          // for(let j=0;j<data[i].courses.length;j++){
          //   if(data[i].courses[j].lec === "000"){
          //     sectionLists.push(data[i].courses[j].lab)
          //   }else if(data[i].courses[j].lab === "000"){
          //     sectionLists.push(data[i].courses[j].lec)
          //   }
          // }
          // report.sections = sectionLists
          report.owner = data[i].owner
          this.itemsReport.push(report)
        }
      }
    },
    async timeThaiFormat (timestamp) {
      let option = {year: 'numeric',month: 'long',day: 'numeric',weekday: 'long',hour: 'numeric',minute: 'numeric',second: 'numeric'}
      return new Date(parseInt(timestamp)*1000).toLocaleString('th-TH', option)
    },
    async showExamResultModalFunc (item, index) {
      console.log(item)
      // let eventReport = await Api.getReportWithEventId(item._id)
      // console.log(eventReport)
      // this.reportID = eventReport.data._id
      this.detailShow = item.detail
      this.getResultReport(item._id)
      this.showExamResultModal = true
    },
    async getResultReport (reportId) {
      this.showloading = true
      let checkinList = await Api.getStudentListWithStatus(reportId,"already")
      console.log("already:",checkinList)
      if(checkinList.status){
        this.reportStatusList.ok = checkinList.data
      }
      let okList = await Api.getStudentListWithStatus(reportId,"completed")
      console.log("completed:",checkinList)
      if(okList.status){
        // this.reportStatusList.ok = okList.data
        this.reportStatusList.ok = this.reportStatusList.ok.concat(okList.data)
      }
      let absentList = await Api.getStudentListWithStatus(reportId,"idle")
      if(absentList.status){
        this.reportStatusList.absent = absentList.data
      }
      let missList = await Api.getStudentListWithStatus(reportId,"miss")
      if(missList.status){
        this.reportStatusList.absent = this.reportStatusList.absent.concat(missList.data)
      }
      let lateList = await Api.getStudentListWithStatus(reportId,"late")
      if(lateList.status){
        this.reportStatusList.late = lateList.data
      }
      let NotFoundList = await Api.getStudentListWithStatus(reportId,"none")
      if(NotFoundList.status){
        this.reportStatusList.NotFound= NotFoundList.data
      }
      console.log("this.reportStatusList",this.reportStatusList)
      this.showloading = false
    },
  }
}
</script>
